<template>
  <div>
    <Head :title="'Defi Farmer'"></Head>
    <div class="center" style="padding: 0 20px;" :style="'width:'+$setWidth">
      <div class="node_title" style="padding-bottom:0;">{{$t('myFarmer.title')}}</div>
      <!-- 等级 -->
      <div class="node_box1 flex_lr">
        <div class="flex_row">
          <img src="@/image/yes.png" class="node_icon" alt="">
          <div class="node_text flex_row">
            <div>{{$t('myFarmer.text1')}}V{{level}}</div>
            <img :src="require('@/image/vip/V' + level+'.png')" style="width:20px;height:20px;margin-left:10px;" alt="">
          </div>
        </div>
        <!-- <div class="node_btn2">激活</div> -->
      </div>
      <!-- 我的算力 -->
      <div class="node_box1 flex_row" v-if="user.power!=undefined">
        <img src="@/image/yes.png" v-if="upLevel[level].power<=user.power||upLevel[level].ng<=user._minerCount_390" class="node_icon" alt="">
        <img src="@/image/no.png" v-else class="node_icon" alt="">
        <div class="node_text">{{$t('myFarmer.text2')}}{{user.power}}</div>
      </div>
      <!-- 我的团队算力 -->
      <div class="node_box1 flex_row">
        <img src="@/image/yes.png" v-if="upLevel[level].team<=info.powerTeam" class="node_icon" alt="">
        <img src="@/image/no.png" v-else class="node_icon" alt="">
        <div class="node_text">{{$t('myFarmer.text3')}}{{info.powerTeam}}</div>
      </div>
      <!-- 我质押的MTG -->
      <div class="node_box1 flex_lr">
        <div class="flex_row">
          <img src="@/image/yes.png" v-if="info.pressureNftTokenId>0" class="node_icon" alt="">
          <img src="@/image/no.png" v-else class="node_icon" alt="">
          <div class="node_text">{{$t('myFarmer.text4')}} {{info.pressureNftTokenId}}</div>
        </div>
        <div class="flex_row">
          <div class="node_btn2" @click="toPage('/nftInfo')">{{$t('myFarmer.text6')}}</div>
          <div class="node_btn2" @click="toPage('/nftInfo')" style="margin-left:5px;">{{$t('myFarmer.text7')}}</div>
        </div>
      </div>
      <!-- 我质押的LP -->
      <div class="node_box1">
        <div class="node_text">{{$t('myFarmer.text8')}}</div>
        <div class="node_box2">
          <div class="flex_lr">
            <!-- <div class="node_text flex_row" style="align-items:flex-start;">
              <img src="@/image/yes.png" v-if="upLevel[level].mt_lp<=user.userLpPledgeMt" class="node_icon" alt="">
              <img src="@/image/no.png" v-else class="node_icon" alt="">
              <div>MT/USDT LP:  {{user.userLpPledgeMt}}</div>
            </div> -->
            <div class="node_text flex_row" style="align-items:flex-start;">
              <img src="@/image/yes.png" v-if="upLevel[level].mt_lp<=pledge.mt" class="node_icon" alt="">
              <img src="@/image/no.png" v-else class="node_icon" alt="">
              <div>MT/USDT LP:  {{pledge.mt}}</div>
            </div>
            <div class="flex_row">
              <div class="node_btn2" @click="toPage('/LP')">{{$t('myFarmer.text6')}}</div>
              <div class="node_btn2" @click="toPage('/LP')" style="margin-left:5px;">{{$t('myFarmer.text7')}}</div>
            </div>
          </div>
          <div class="flex_lr">
            <!-- <div class="node_text flex_row" style="align-items:flex-start;">
              <img src="@/image/yes.png" v-if="upLevel[level].mt_lp<=user.userLpPledgeUlm" class="node_icon" alt="">
              <img src="@/image/no.png" v-else class="node_icon" alt="">
              <div>ULME/USDT LP:  {{user.userLpPledgeUlm}}</div>
            </div> -->
            <div class="node_text flex_row" style="align-items:flex-start;">
              <img src="@/image/yes.png" v-if="upLevel[level].mt_lp<=pledge.ulm" class="node_icon" alt="">
              <img src="@/image/no.png" v-else class="node_icon" alt="">
              <div>ULME/USDT LP:  {{pledge.ulm}}</div>
            </div>
            <div class="flex_row">
              <div class="node_btn2" @click="toPage('/LP')">{{$t('myFarmer.text6')}}</div>
              <div class="node_btn2" @click="toPage('/LP')" style="margin-left:5px;">{{$t('myFarmer.text7')}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 质押的代币 -->
      <div class="node_box1">
        <div class="node_text">质押的代币</div>
        <div class="node_box2">
          <div class="flex_lr">

            <div class="node_text flex_row" style="align-items:flex-start;">
              <img src="@/image/yes.png" v-if="upLevel[level].mt_pledge<=user.userPledgeMt" class="node_icon" alt="">
              <img src="@/image/no.png" v-else class="node_icon" alt="">
              <div>MT:  {{user.userPledgeMt}}</div>
            </div>
            <div class="flex_row">
              <div class="node_btn2" @click="pledgeClick(0)">{{$t('myFarmer.text6')}}</div>
              <div class="node_btn2" @click="pledgeTake(0)" style="margin-left:5px;">{{$t('myFarmer.text7')}}</div>
            </div>
          </div>
          <div class="flex_lr">
            <div class="node_text flex_row" style="align-items:flex-start;">
              <img src="@/image/yes.png" v-if="upLevel[level].ulm_pledge<=user.userPledgeUlm" class="node_icon" alt="">
              <img src="@/image/no.png" v-else class="node_icon" alt="">
              <div>ULME:  {{user.userPledgeUlm}}</div>
            </div>
            <div class="flex_row">
              <div class="node_btn2" @click="pledgeClick(1)">{{$t('myFarmer.text6')}}</div>
              <div class="node_btn2" @click="pledgeTake(1)" style="margin-left:5px;">{{$t('myFarmer.text7')}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 我的有效布道数量 -->
      <div class="node_box1 flex_row">
        <img src="@/image/yes.png" v-if="upLevel[level].count<=info.childCount" class="node_icon" alt="">
        <img src="@/image/no.png" v-else class="node_icon" alt="">
        <div class="node_text">{{$t('myFarmer.text9')}}{{info.childCount}}{{$t('myFarmer.text10')}} V0 </div>
      </div>
      <!-- 待领取收益 -->
      <div v-if="info.pressureNftTokenId>0">
      <div class="node_title" style="padding: 0;">{{$t('myFarmer.text11')}}</div>
      <div class="node_box1">
        <div class="node_box2">
          <div class="node_text">{{$t('myFarmer.text12')}}</div>
          <div class="node_text">{{info.mt10000}}MT/{{info.ulm10000}}ULME </div>
        </div>
        <div v-if="info.mtV!=undefined" class="node_box2" style="color:#fff;margin-top:10px;">
          <div class="node_text">V{{level}} {{$t('myFarmer.text13')}}</div>
          <div class="node_text">{{info.mtV}}MT/{{info.ulmV}}ULME </div>
        </div>
        <div class="node_btn2" style="padding:10px;margin-top:10px;" @click="takeBranch">{{$t('myFarmer.text14')}}</div>
      </div>
      </div>
          <!-- 手动升级 -->
    <div class="bind flex_center" v-if="addPop2" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop2=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>{{$t('myFarmer.text15')}}</div>
          </div>
          <button @click="updataLevel">{{$t('myFarmer.text16')}}</button>
        </div>
      </div>
    </div>
    <!-- 质押代币 -->
    <div class="bind flex_center" v-if="addPop1" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop1=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>{{$t('myFarmer.text6')}}{{pledgeType==0?'MT':'ULME'}}</div>
            <input type="number" v-model="buyNumber"  placeholder="请输入数量">
          <div :class="approve_mt>=Number(buyNumber)?'LP_btn_after':'LP_btn2'" v-if="pledgeType==0&&approve_mt<Number(buyNumber)"  style="flex:1;padding: 10px;margin:0 5px;" @click="approveMt">{{$t('Miner.text13')}}MT</div>
          <div :class="approve_ulm>=Number(buyNumber)?'LP_btn_after':'LP_btn2'" v-if="pledgeType>0&&approve_ulm<Number(buyNumber)"  style="flex:1;padding: 10px;margin:0 5px;" @click="approveMt">{{$t('Miner.text13')}}ULME</div>

          </div>
          <button @click="pledgeFun">{{$t('myFarmer.text6')}}</button>
        </div>
      </div>
    </div>
    <!-- 质押代币 end-->

    </div>
    <div style="height: 20px;"></div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';
export default {
  data () {
    return {
      info:{},
      user:{},
      level:0,
      pledgeType:0,
      buyNumber:'',
      addPop2:false,
      addPop1:false,
      pledge:{}
    }
  },
  computed: {
    upLevel(){
      return this.$store.state.user.levelconfig
    }
  },
  async mounted () {
    await this.$onLaunched;
    if(this.upLevel.length==0){
      walletHelper.getLevelconfig()
    }
    this.init()
  },
  methods: {
        dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
        closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    takeBranch(){
      const that=this
      this.sendWeb3(walletHelper.getContract('dao').methods.takeBranch()).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('领取成功')
      })
    },
    pledgeClick(type){
      this.pledgeType=type
      this.addPop1=true
    },
    pledgeFun(){
      if(this.pledgeType>1){
        this.$toast('输入错误')
        return
      }
      if(this.buyNumber==''||this.buyNumber==0){
        this.$toast('请输入要质押的数量')
        return
      }
      const that=this


      this.sendWeb3(walletHelper.getContract('daoV4').methods.pledge(walletHelper.getContractAddress('rel'),walletHelper.toWei(String(this.buyNumber)),this.pledgeType)).then((result)=>{
        console.log(result)
        that.init()
        that.closeLoading('质押成功')
        this.addPop1=false
      })
    },
    pledgeTake(type){
      const that=this

      this.sendWeb3(walletHelper.getContract('daoV4').methods.takePledge(walletHelper.getContractAddress('rel'),type)).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('领取成功')
      })
    },
    getApprove(){
      const that=this
      walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV4')).call().then(res=>{
          console.log('approve_mt',walletHelper.Wei(res))
          this.approve_mt=walletHelper.Wei(res)
      })
      walletHelper.getContract('ulm').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV4')).call().then(res=>{
          console.log('approve_ulm',walletHelper.Wei(res))
          this.approve_ulm=walletHelper.Wei(res)
      })
    },
    approveMt(){
      const that=this
      let token=this.pledgeType==0?'mt':'ulm'
      console.log(token)

      this.sendWeb3(walletHelper.getContract(token).methods.approve(walletHelper.getContractAddress('daoV4'),walletHelper.toWei(String(this.buyNumber)))).then((result)=>{
        console.log(result)
        that.getApprove()
        that.closeLoading('授权成功')
      })
    },
    init() {
      const that=this
      // this.get('', {}, 'POST').then(res => {
        
      // })
      this.getApprove()
      walletHelper.getContract('rel').methods.getPressureNftTokenId(walletHelper.getAddress()).call().then(res=>{
        console.log(res)
        that.$set(that.info,'pressureNftTokenId',res>0?res:that.$t('myFarmer.text5'))
        if(that.info.pressureNftTokenId>0){
          walletHelper.getContract('daoV1').methods.getBranchCount(walletHelper.getAddress()).call().then((result)=>{
            that.$set(that.info,'mt10000',walletHelper.Wei(result[0]))
            that.$set(that.info,'ulm10000',walletHelper.Wei(result[1]))
            if(result[2]>0||result[3]>0){
              that.$set(that.info,'mtV',walletHelper.Wei(result[2]))
              that.$set(that.info,'ulmV',walletHelper.Wei(result[3]))
            }
          })
        }
      })

      walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
        if(res){
          that.user=res
          // if(Number(res.parentReward)>0){
          //   walletHelper.getContract('query').methods.getUsdtToToken(walletHelper.getContractAddress('mt'),res.parentReward).call().then(res=>{
          //     that.$set(that.user,'parentReward',walletHelper.Wei(res))
          //   })
          //   }
            if(Number(res.power)>0||res._minerCount_390>0){
              walletHelper.getContract('daoV1h').methods.getTeamPower(walletHelper.getAddress()).call().then(res=>{
                console.log(res)
                that.$set(that.info,'powerTeam',res)

              })
            }
            that.level=res.userLevel
            walletHelper.getContract('daoV4').methods.getLevel(walletHelper.getContractAddress('rel'),walletHelper.getAddress()).call().then(level=>{
              if(that.level!=level){
                that.addPop2=true
              }
            })
            

          }else{
            console.log('getUserData错误  error',e)
          
        }
      })
      walletHelper.getContract('daoV1').methods.data(walletHelper.getAddress(),String(13)).call().then((result)=>{
        that.$set(that.pledge,'mt',walletHelper.Wei(result))
      })
      walletHelper.getContract('daoV1').methods.data(walletHelper.getAddress(),String(14)).call().then((result)=>{
        that.$set(that.pledge,'ulm',walletHelper.Wei(result))
      })

      walletHelper.getContract('daoV1').methods.getChildMintCount(walletHelper.getAddress()).call().then((result)=>{
        that.$set(that.info,'childCount',result)
      })


    },
    updataLevel(){
      const that=this
      this.sendWeb3(walletHelper.getContract('dao').methods.updateLevel(walletHelper.getAddress())).then((result)=>{
        console.log(result)
        that.init()
        that.closeLoading('升级成功')
        that.addPop2=false
      })
    },
    // 领取详情
    toReceivingDetails(){
      this.$router.push('/Receiving_details')
    },
    toPage(url){
      this.$router.push(url)
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .node_icon{
    width: 15px;
    margin-right: 10px;
  }
  .node_title{
    color: #00FFEA;
    font-size: 1.1rem;
    padding: 10px 0;
  }
  .node_subtitle{
    font-size: 0.95rem;
    font-family: Microsoft YaHei;
    color: #00FFEA;
    padding: 10px 0;
    i{
      margin-left: 5px;
      font-size: 0.95rem;
    }
  }
  .node_title2{
    color: #ffffff;
    font-size: 1rem;
    padding-bottom: 10px;
  }
  .node_text{
    color: #ffffff;
    font-size: 0.8rem;
    padding: 8px 0;
  }
  .node_box1{
    width: 100%;
    background: #0C2379;
    border-radius: 8px;
    padding: 10px 13px;
    margin: 15px 0;
  }
  .node_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 5px 13px;
    color: #ffffff;
  }
  .node_btn3{
    width: 100%;
    background: #00FFEA;
    border-radius: 8px;
    padding: 10px 13px;
    text-align: center;
  }
  .node_btn{
    text-align: center;
    border-radius: 5px;
    color: #05faeb;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 3px 18px;
    border: 1px solid #05faeb;
    white-space: nowrap;
  }
  .node_btn2{
    text-align: center;
    background: #05faeb;
    border-radius: 5px;
    color: #000;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 5px 8px;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #00FFEA;
    border-radius: 5px;
    color: #000000;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .LP_btn_after{
    text-align: center;
    background: #f0f0f0;
    border-radius: 5px;
    color: #999999;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
    .bind{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .bind_bg{
      background: #21C7D5;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .bind_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .bind_text{
          color: #CE0F0F;
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 5px;
        }
        .bind_text1{
          color: rgb(14, 245, 137);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        .bind_text2{
          color: rgb(233, 99, 22);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        input{
          font-size: 16px;
          // width: 0;
          // flex: 1;
          width: 100%;
          padding: 15px 15px;
          background: #ffffff;
          border-radius: 3px;
          // min-height: 100px;
          color: #00DEFF;
          line-height: 18px;
          margin: 15px 0 10px;
        }
        // chrome
        input::-webkit-input-placeholder { /* WebKit browsers */
          color: #999999;
          font-size:15px;
        }
        // firefox
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #999999;
          font-size:15px;
        }
        button{
          background: #ffffff;
          color: #177C85;
          font-size: 14px;
          border: none;
          // margin-left: 10px;
          border-radius: 3px;
          height: 40px;
          // width: 80px;
          width: 100%;
        }
      }
    }
  }
</style>